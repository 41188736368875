<template>
  <div>
    <b-modal
      id="new-bonus-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      centered
      no-close-on-backdrop
      scrollable
      content-class="new-bonus-modal"
      @hide="resetForm"
    >
      <span
        class="close-btn shadow-sm"
        @click="$bvModal.hide('new-bonus-modal')"
      >
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New Bonus</h3>
      <span
        class="d-none justify-content-center text-danger"
        :class="hasError"
        >{{ processingError }}</span
      >
      <form @submit="createNewBonus" @reset="resetForm">
        <div class="input-row row">
          <div class="col-md-8 d-flex align-items-center">
            <div class="image-upload w-100">
              <label for="bonus-image-input" class="image-upload-label">
                <span class="icon">
                  <i class="fa fa-camera"></i>
                  <span class="text">Upload Image</span>
                </span>
              </label>
              <input
                @change="uploadFile"
                type="file"
                name="bonusImage"
                id="bonus-image-input"
                class="mt-3"
                ref="bonusImageRef"
              />
            </div>
          </div>
          <div class="col-md-4 level-image-preview">
            <template v-if="imageUrl !== null">
              <img :src="imageUrl" alt="Level image" />
            </template>
            <template v-else>
              <h6>No image added</h6>
            </template>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-md-6">
            <label for="name">Name</label>
            <b-form-input
              required
              v-model="form.name"
              placeholder="Enter Bonus Name"
            ></b-form-input>
          </div>
          <div class="col-md-6">
            <label for="minOrders">Min Orders.</label>
            <b-form-input
              required
              type="number"
              v-model="form.minOrders"
              placeholder="No. of Orders"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row row">
          <div class="col-md-4">
            <label for="rewards">Bonus Amount</label>
            <b-form-input
              required
              type="number"
              v-model="form.rewards"
              placeholder="Enter Bonus Amount"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="minRating">Min Rating</label>
            <b-form-input
              required
              type="number"
              step="any"
              :min="0"
              :max="5"
              v-model="form.minRating"
              placeholder="Minimum Avg. Rating"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="penalties">Penalties</label>
            <b-form-input
              type="number"
              v-model="form.penalties"
              placeholder="No of Penalties"
            ></b-form-input>
          </div>
          <div class="col-md-4">
            <label for="penalties">Penalties</label>
            <b-form-input
              type="number"
              v-model="form.number"
              placeholder="No of Penalties"
            ></b-form-input>
          </div>
        </div>
        <div class="input-row">
          <span class="bold-label">Bonus Time Frame</span>
          <b-form-radio-group
            id="time-frame"
            v-model="form.timeFrame"
            :options="timeFrameOptions"
            name="time-frame"
          ></b-form-radio-group>
        </div>
        <div class="input-row row">
          <label for="description">Description</label>
          <b-form-textarea
            id="textarea"
            v-model="form.description"
            placeholder="Description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>
        <div class="input-row center">
          <b-button
            type="Submit"
            class="create-button"
            :class="processingRequest"
            :disabled="processingRequest !== ''"
            >Submit
            <img
              class="ajax-loader"
              src="/media/ajax/white.gif"
              alt="Ajax loader"
          /></b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
export default {
  data() {
    return {
      form: {
        name: "",
        description: "",
        minOrders: "",
        minRating: 0,
        rewards: "",
        timeFrame: "Daily",
        penalties: "",
        number: "",
      },

      timeFrameOptions: [
        { text: "Daily", value: "Daily" },
        { text: "Fortnightly", value: "Fortnightly" },
        { text: "Weekly", value: "Weekly" },
        { text: "Monthly", value: "Monthly" },
      ],
      hasError: false,
      processingError: "",
      processingRequest: "",
      bonusImage: null,
      imageUrl: null,
    };
  },
  methods: {
    uploadFile: function () {
      this.bonusImage = this.$refs.bonusImageRef.files[0];
      this.imageUrl = URL.createObjectURL(this.bonusImage);
    },
    showModal: function () {
      this.$bvModal.show("new-bonus-modal");
    },
    closeModal: function () {
      this.$bvModal.hide("new-bonus-modal");
    },
    resetForm: function () {
      const defaultForm = {
        name: "",
        description: "",
        minOrders: "",
        minRating: null,
        rewards: "",
        timeFrame: "daily",
        penalties: "",
      };
      for (let key of Object.keys(defaultForm)) {
        this.form[key] = defaultForm[key];
      }
      this.levelImage = null;
    },
    createNewBonus: function (e) {
      e.preventDefault();
      const tk = JwtService.getToken();
      this.form.token = tk;
      this.processingRequest = "processing";
      this.form.rewards = parseInt(this.form.rewards, 10);
      ApiService.post("/bonuses/addBonus", this.form).then((res) => {
        if (res.data.success === 0) {
          this.hasError = "d-flex";
          this.processingError = res.data.message;

          setTimeout(() => {
            this.hasError = "d-none";
            this.processingError = "";
          }, 3000);
          return;
        }
        if (res.data.success) {
          if (this.bonusImage !== null) {
            const newFormData = new FormData();
            newFormData.append("image", this.bonusImage);
            newFormData.append("bonusId", res.data.data._id);
            newFormData.append("token", `${tk}`);
            ApiService.post("/bonuses/uploadImage", newFormData).then((res) => {
              if (res.data.success === 0) {
                this.hasError = "d-flex";
                this.processingError = res.data.message;
                setTimeout(() => {
                  this.hasError = "d-none";
                  this.processingError = "";
                }, 3000);
              } else {
                this.$emit("addedNewBonus");
              }
            });
          } else {
            this.$emit("addedNewBonus");
          }
        }
        setTimeout(() => {
          this.processingRequest = "";
        }, 400);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
  .new-bonus-modal {
    background: #fff;
    position: relative;
    .close-btn {
      position: absolute;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      right: 15px;
      cursor: pointer;
      background: #aeacac;
      i {
        color: #fff;
        font-size: 14px;
      }
    }
    .modal-title {
      width: 100%;
      text-align: center;
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .input-row {
      padding: 10px 0;
      .form-control {
        border-radius: 0;
      }
      &.center {
        display: flex;
        justify-content: center;
      }
      .bv-no-focus-ring {
        display: flex;
        justify-content: space-between;
      }
      .bold-label {
        width: 100%;
        padding: 15px;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .level-image-preview {
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: auto;
      }
    }
    .image-upload {
      label {
        display: flex;
        align-items: center;
        span.icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;

          &.attachment {
            display: flex;
            .text {
              max-width: 100px;
              text-align: center;
            }
          }
          i {
            font-size: 32px;
            color: #000;
          }
          padding: 25px;
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin-right: 10px;
          span.text {
            color: #808080;
          }
        }

        margin: 0;
        cursor: pointer;
      }
      input {
        display: none;
      }
    }
  }
}
</style>
