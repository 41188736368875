<template>
  <div class="single-bonus shadow-sm">
    <div class="upper-block">
      <div
        class="bonus-block shadow-sm"
        :style="
          bonus && bonus.image
            ? 'background:linear-gradient(to bottom, rgba(0,0,0,0.5) 100%, rgba(0,0,0,0.1) 100%),url(' +
              bonus.image +
              ');'
            : 'background:#e72a37;'
        "
      >
        <div class="bonus-text">
          <div class="bonus-heading">{{ bonus ? bonus.name : "..." }}</div>
          <div class="bonus-name">{{ bonus ? bonus.description : "..." }}</div>
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Time Frame</div>
            <div class="info-value">{{ bonus ? bonus.timeFrame : "..." }}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Bonus Amount</div>
            <div v-if="region === 'PAKISTAN'" class="info-value">
              PKR{{ bonus ? bonus.rewards : "0" }}
            </div>
            <div v-if="region === 'US'" class="info-value">
              ${{ bonus ? bonus.rewards : "0" }}
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <span class="action-btn edit" @click="editBonus(index)">Edit</span>
          <span
            v-bind:class="!archived ? 'archive' : 'unarchive'"
            class="action-btn"
            >{{ archived ? "Unarchive" : "Archive" }}</span
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Rating</div>
            <div v-if="region === 'PAKISTAN'" class="info-value">
              {{ bonus ? bonus.minRating : "1" }}+
            </div>
            <div v-if="region === 'US'" class="info-value">
              Min Rating :{{ bonus ? bonus.minRating : "1" }}
            </div>
          </div>
        </div>
        <div v-if="region === 'PAKISTAN'" class="col-md-4">
          <div class="info-block">
            <div class="info-head">Allowed Penalties</div>
            <div class="info-value">{{ bonus ? bonus.penalties : "1" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    bonus: Object,
    archived: Boolean,
    index: Number,
  },
  methods: {
    editBonus: function (index) {
      this.$emit("editBonus", index);
    },
  },
  computed: {
    ...mapState({
      region: (state) => state.region.isRegion,
    }),
  },
};
</script>

<style lang="scss" scoped>
.single-bonus {
  width: 100%;
  max-width: 525px;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px;

  .upper-block {
    .bonus-block {
      border-radius: 0 0 10px 10px;
      height: 200px;
      background-size: cover !important;
      background-position: center top !important;
      display: flex;
      align-items: center;
      .bonus-text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        .bonus-heading {
          font-size: 40px;
          line-height: 0.75;
          color: #fff;
        }
        .bonus-name {
          font-size: 28px;
          color: #fff;
        }
      }
    }
  }

  .lower-block {
    padding: 20px;
    .row {
      margin-bottom: 10px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      .info-head {
        font-weight: 600;
      }
      .info-value {
        color: #bebdbd;
      }
    }
    .action-btn {
      color: #fff;
      cursor: pointer;
      padding: 8px 25px;
      border-radius: 20px;
      &.edit {
        background: #4ff86f;
        margin-right: 5px;
      }
      &.unarchive {
        background: #50a6fa;
      }
      &.archive {
        background: #f8b040;
      }
    }
  }
}
</style>
