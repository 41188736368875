<template>
  <div class="archived-levels">
    <h3 class="heading-text">Archived</h3>
    <div class="items">
      <b-col cols="6"><SingleLevel :archived="true" /></b-col>
      <b-col cols="6"><SingleLevel :archived="true" /></b-col>
      <b-col cols="6"><SingleLevel :archived="true" /></b-col>
    </div>
  </div>
</template>

<script>
import SingleLevel from "@/view/pages/bonus-levels/SingleLevel";
export default {
  components: {
    SingleLevel,
  },
};
</script>

<style lang="scss" scoped>
.archived-levels {
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
