<template>
  <div class="single-level shadow-sm">
    <div class="upper-block">
      <div class="level-block shadow-sm">
        <div class="level-badge">
          <img
            :src="
              levelDetails && levelDetails.image
                ? levelDetails.image
                : '/media/local/wooden-shield.png'
            "
            alt
          />
        </div>
        <div class="level-text">
          <div class="level-number">
            Level {{ levelDetails ? levelDetails.baskytorLevel : "..." }}
          </div>
          <div class="level-name">
            {{ levelDetails ? levelDetails.name : "..." }}
          </div>
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Req. Orders</div>
            <div class="info-value">
              {{ levelDetails ? levelDetails.orderTarget : "..." }}
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Avg. Rating</div>
            <div class="info-value">
              {{ levelDetails ? levelDetails.ratingTarget : "..." }}+
            </div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-end">
          <span class="action-btn edit" @click="editLevel(index)">Edit</span>
          <span
            v-bind:class="!archived ? 'archive' : 'unarchive'"
            class="action-btn"
            >{{ archived ? "Unarchive" : "Archive" }}</span
          >
        </div>
      </div>
      <div v-if="region === 'PAKISTAN'" class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Baskytors</div>
            <div class="info-value">0</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Pay/hr</div>
            <div class="info-value">
              {{ levelDetails ? `PKR ${levelDetails.pay}` : "..." }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="region === 'US'" class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Baskytors On This Level</div>
            <div class="info-value">{{ totalRiders ? totalRiders : 0 }}</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Level Premium</div>
            <div class="info-value">
              {{ levelDetails ? `$${levelDetails.levelPremium}` : "..." }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    archived: Boolean,
    levelDetails: Object,
    index: Number,
  },
  methods: {
    editLevel: function (index) {
      this.$emit("editLevel", index);
    },
    computed: {
      ...mapState({
        region: (state) => state.region.isRegion,
      }),
    },
  },
};
</script>

<style lang="scss" scoped>
.single-level {
  width: 100%;
  max-width: 525px;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px;

  .upper-block {
    .level-block {
      border-radius: 0 0 10px 10px;
      height: 200px;
      background: #eccb94;
      display: flex;
      align-items: center;

      .level-badge {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        padding: 15px;
        img {
          height: 150px;
          width: auto;
          max-width: 100%;
        }
      }
      .level-text {
        padding: 15px;
        width: 60%;
        display: flex;
        flex-direction: column;

        .level-number {
          font-size: 32px;
          font-weight: 600;
          line-height: 0.75;
        }
        .level-name {
          font-size: 28px;
          color: #fff;
        }
      }
    }
  }

  .lower-block {
    padding: 20px;
    .row {
      margin-bottom: 10px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      .info-head {
        font-weight: 600;
      }
      .info-value {
        color: #bebdbd;
      }
    }
    .action-btn {
      color: #fff;
      cursor: pointer;
      padding: 8px 25px;
      border-radius: 20px;
      &.edit {
        background: #4ff86f;
        margin-right: 5px;
      }
      &.unarchive {
        background: #50a6fa;
      }
      &.archive {
        background: #f8b040;
      }
    }
  }
}
</style>
